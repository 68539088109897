import React from "react";
import "./contact-section.scss";
import ContactForm from "./ContactForm";

function ContactSection() {
  return (
    <section className="contact" id="contact">
      <div className="container">
        <div className="contact-container">
          <ContactForm />
        </div>
      </div>
    </section>
  );
}

export default ContactSection;
